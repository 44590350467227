<template>

    <van-popup
      :value="value"
      @input="val => this.$emit('input', val)"
     :class="isLandscape ? 'landscape-setting' : ''"
     :position="isLandscape ? 'right' : 'bottom'"
      round
      close-icon="arrow-left"
      close-icon-position="top-left"
      :style="{ height: isLandscape ? '100%' : '80%' }"
      :get-container="getContainer"

    >

      <div class="content">
        <div class="titBox">
          <van-icon name="arrow-left" @click="() => this.$emit('input', false)" class="icon" size="24" color="#000" />
          <div class="tit">会议设置</div>
        </div>
        <div class="info">安全</div>

        <div class="cell">
          <van-cell
            center
            size="32"
            class="item borderNo"
            :title="$t('meeting.lockMeet')"
          >
            <template #right-icon>
              <van-switch @change="lockMeetChange" :value="!!lockedState" size="24" />
            </template>
          </van-cell>
        </div>
        <div class="info" v-if="isShowShareSet() || isShowRecordSet()">{{ $t('meeting.participantRights') }}</div>
        <div class="cell" v-if="isShowShareSet()">
          <van-cell
            center
            size="32"
            class="item"
            :title="$t('meeting.lockShare')"
          >
            <template #right-icon>
              <van-switch @change="shareSetChange" :value="!!sharePermission" size="24" />
            </template>
          </van-cell>
        </div>
        <!-- <div class="cell" v-if="isShowRecordSet()">
          <van-cell
            center
            size="32"
            class="item borderNo"
            :title="$t('meeting.lockRecord')"
          >
            <template #right-icon>
              <van-switch @change="recordSetChange" :value="!!recordPermission" size="24" />
            </template>
          </van-cell>
        </div> -->
        <div class="info">{{$t('meeting.setMeeting')}}</div>
        <div class="cell">
          <van-cell
            center
            size="32"
            class="item"
            :title="$t('meeting.noVoiceMeet')"
          >
            <template #right-icon>
              <van-switch  @change="muteJoinMeetingChange" :value="!!muteJoinMeeting" size="24" />
            </template>
          </van-cell>
        </div>
        <div class="cell">
          <van-cell
            center
            size="32"
            class="item"
            :title="$t('login.relieve')"
          >
            <template #right-icon>
              <van-switch @change="allowSelfUnmuteChange" :value="!!allowSelfUnmute" size="24" />
            </template>
          </van-cell>
        </div>
        <div class="cell">
          <van-cell
            center
            size="32"
            class="item borderNo"
            :title="$t('meeting.tipsVoice')"
          >
            <template #right-icon>
              <van-switch @change="playTipsChange" :value="!!playTips" size="24" />
            </template>
          </van-cell>
        </div>
      </div>
      

    </van-popup>

</template>

<script>
import { CONTROL_COMMAND, ROLE_CODE } from "@/constant/index";
import { fetchMeetControl } from '@/api/meet'
import { loganLog } from "@/utils/log"

export default {
  name: "MeetSetting",
  data() {
    return {
      meetChecked:true
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // callback: {
    //   type: Function,
    //   default: () => {},
    // },
  },
  computed: {
    isLandscape () {
      return this.$store.state.meet.isLandscape
    },
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },
    lockedState() {
      // 会议锁定
      return this.$store.state.meet.lockedState;
    },
    muteJoinMeeting() {
      return this.$store.state.meet.muteJoinMeeting;
    },
    allowSelfUnmute() {
      return this.$store.state.meet.allowSelfUnmute;
    },
    playTips() {
      return this.$store.state.meet.playTips;
    },
    sharePermission() {
      return this.$store.state.meet.sharePermission;
    },
    recordPermission() {
      return this.$store.state.meet.recordPermission
    },
    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },
    userList() {
      // 成员列表
      return this.$store.state.member.userList
    },
    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    },
  },
  methods: {
    isShowShareSet() { // 是否显示分享按钮右侧设置
      // return !this.isOrdinaryUser

      if (!this.isOrdinaryUser) { // 主持人和联席
        return true
      } else { // 普通用户
        return this.isExitUserShare && this.isExitUserShare.userId === this.$configs.peerId
      }
    },

    async lockMeetChange(e) {
      // 锁定会议室
      // let lockedState;
      let command;

      if (e) {
        // 锁定
        // lockedState = 1;
        command = CONTROL_COMMAND.LOCK_CONFERENCE;
      } else {
        // 解锁
        // lockedState = 0;
        command = CONTROL_COMMAND.UNLOCK_CONFERENCE;
      }
      try {
        await fetchMeetControl({
          command
        }, this.$configs)
      } catch (error) {
        loganLog(error, '会控异常|会议设置异常|锁定会议')
      } 
    },
    async muteJoinMeetingChange(e) {
      // let muteJoinMeeting;
      let command;

      if (e) {
        // muteJoinMeeting = 1;
        command = CONTROL_COMMAND.OPEN_MUTE_JOIN_MEETING;
      } else {
        // muteJoinMeeting = 0;
        command = CONTROL_COMMAND.CLOSE_MUTE_JOIN_MEETING;
      }
      
      try {
        await fetchMeetControl({
          command
        }, this.$configs)
      } catch (error) {
        loganLog(error, '会控异常|会议设置异常|成员入会时静音')
      }

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   muteJoinMeeting,
      // });
    },
    async allowSelfUnmuteChange(e) {
      // let allowSelfUnmute;
      let command;

      if (e) {
        // allowSelfUnmute = 1;
        command = CONTROL_COMMAND.ALLOW_SELF_UNMUTE;
      } else {
        // allowSelfUnmute = 0;
        command = CONTROL_COMMAND.FORBID_SELF_UNMUTE;
      }
      try {
        await fetchMeetControl({
          command
        }, this.$configs)
      } catch (error) {
        loganLog(error, '会控异常|会议设置异常|允许成员自我解除静音')
      }

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   allowSelfUnmute,
      // });
    },
    async playTipsChange(e) {
      // let playTips;
      let command;

      if (e) {
        // playTips = 1;
        command = CONTROL_COMMAND.OPEN_PLAY_TIPS;
      } else {
        // playTips = 0;
        command = CONTROL_COMMAND.CLOSE_PLAY_TIPS;
      }

      try {
        await fetchMeetControl({
          command
        }, this.$configs)
      } catch (error) {
        loganLog(error, '会控异常|会议设置异常|成员进入时播放提示音')
      }
    },

    async shareSetChange(e) {
      //主持人选择仅主持可共享，弹窗是否结束当前非主持人的共享
      if(this.isExitUserShare && e == 1 && this.isExitUserShare.roleCode !== ROLE_CODE.CO_HOST && this.isExitUserShare.roleCode !== ROLE_CODE.HOST){
        //是否停止共享弹窗
        const commonTipText =  this.$t('meeting.stopSharing') //this.$t('meeting.isStopShare') 
        this.$dialog.confirm({
          className: 'default-dialog',
          title: commonTipText,
          cancelButtonText: this.$t('meeting.noStop'),
          confirmButtonText: this.$t('meeting.isStop')
        }).then(
          () => {
            this.stopShareHandle()
          }
        )
      }
      try {
        await fetchMeetControl({
          command: e ?
            CONTROL_COMMAND.SHARE_PERMISSIONS_HOST : CONTROL_COMMAND.SHARE_PERMISSIONS_ALL,
        }, this.$configs)
      } catch (error) {
        loganLog(error, '会控异常|会议设置异常|锁定共享')
      }
     
    },

    async stopShareHandle() {
        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.STOP_SHARE,
            toPeerId: this.isExitUserShare.userId
          }, this.$configs)
          
        } catch (error) {
          console.error(error)
          loganLog(error, '会控异常|会议设置异常|停止共享')
        }
    },

    isShowRecordSet() { // 是否显示录制按钮右侧设置
      return !this.isOrdinaryUser
    },

    async recordSetChange(e) {
      try {
        await fetchMeetControl({
          command: e ? CONTROL_COMMAND.RECORD_PERMISSIONS_HOST : CONTROL_COMMAND.RECORD_PERMISSIONS_ALL
        }, this.$configs)
      } catch (error) {
        loganLog(error, '会控异常|会议设置异常|锁定录制')
      }
    },

    getContainer() {
      return document.querySelector('.meeting-page')
    }
  },
};
</script>

<style scoped  lang="less">
.content {
  background: #fafafa;
  width: 100%;
  .titBox {
    width: 100%;
    height: 88px;
    position: relative;
    .tit {
      width: 100%;
      height: 88px;
      line-height: 88px;
      text-align: center;
      color: #000;
      font-size: 32px;
      font-weight: 600;
    }
    .icon {
      position: absolute;
      left: 40px;
      top: 20px;
    }
  }
  .info {
    width: 100%;
    height: 64px;
    line-height: 64px;
    box-sizing: border-box;
    font-size: 24px;
    padding-left: 40px;
    color: #666;
  }
}
.cell {
  padding-left: 40px;
  background: #fff;
  box-sizing: border-box;
}
.item {
  height: 104px;
  border-bottom: 1px solid #eee;
  padding: 0 40px 0 0;
  font-size: 32px;
}
.borderNo{
  border: none;
}


/******************* 横竖屏切换 样式 ********************* */
.landscape-setting {
  width: 60%;
  height: 750px;
}
</style>
