


<template>

  <div
    class="video-wrapper"
    v-if="user"
  >
    <!-- 媒体组件 -->
    <video
      v-show="video"
      autoplay
      webkit-playsinline
      playsinline
      muted
      :id="id"
    ></video>

    <!-- userInfo -->
    <div class="user-info">

      <!-- 主持人icon 显示 -->
      <my-icon
        v-if="isShowHost"
        iconName="icon-host"
        fontSize="40"
      ></my-icon>
      <my-icon
        v-else-if="isShowCoHost"
        fontSize="40"
        iconName="icon-cohost"
      ></my-icon>


      <my-icon
        v-if="user.isUseHuaTongError"
        iconName="icon-shipinlan_shengyinyichang_32_bai"
        fontSize="32"
      ></my-icon>

      <my-icon
        v-else
        :iconName="[user.isUseHuaTong ?
                `icon-solid-volume-${user.volume || 0}` :
                `icon-solid-mic-close`]"
        fontSize="32"

      ></my-icon>
      
      <!-- ;;  user.isShare && isFocusScreen==0 && speakerView && user.userId.indexOf('cloudshare') == -1-->
      <span>{{user.isTopShare || user.isCopyShare && $parent.enlargeVideoShow || speakerView && isFocusScreen==0 && user.isShare && !noSubscribeShare?  user.userName + '的共享' : user.userName }}</span>

    </div>

    <!-- 无视频占位图 -->
    <div
      v-if="!video"
      class='no-video-placeholder'
    >
      <my-icon
        v-if="status == 'codecUnsupport' && user.isUseShiPin"
        iconName="icon-av1"
        fontSize="80"
      ></my-icon>
      <img v-else-if='user.avatarUrl' :src="user.avatarUrl" class="avatar" mode="aspectFill" />
      <div v-else class="name">{{ user.userName ? user.userName.slice(0, 1) : ''  }}</div>
    </div>

    <!-- 在说话高亮 -->
    <div
      v-if="isSpeaking"
      class="speaking-highlight"
    ></div>


    <van-loading
      v-if="video && status == 'loading'"
    />

    <!-- 共享暂停 -->
    <div
        v-if="sharePaused"
        class="share-paused"
    >
        <div>{{$t('meeting.shareTop')}}</div>
    </div>


  </div>

</template>


<script>


import { ROLE_CODE } from "@/constant/index";
import * as retry from 'retry'
import { loganLog } from '@/utils/log';

export default {

    props: {
      user: Object,
      speakerView: Boolean,
      notHighlight: Boolean, // 不高亮
      noSubscribeShare: Boolean, //不订阅 共享 (存在于演讲者视图 右上角)

      page: Number, // 组件所在页面
    },


    data() {
      return {
        ROLE_CODE: ROLE_CODE,
        status: 'loading',

        noRemoteVideo: false, // 不在本页 不订阅视频
      }
    },

    computed: {

      id() {

        const { userId } = this.user;

        return `video-${userId}`
      },

      // 共享流 或者 视频流
      video() {
        if(this.status == 'codecUnsupport') return false; // 不支持的编码能力

        if(this.user.isTopShare) return true; // 共享
        if(this.user.isCopyShare && this.$parent.enlargeVideoShow) return true; // 共享弹窗
        if(this.noRemoteVideo) return false;
        if(this.user.isUseShiPin) return true;
        if(
          this.user.isShare
          && this.speakerView
          && !this.noSubscribeShare
          && this.isFocusScreen == 0
        ) return true; 

        return false
      },
      isShowHost() {
        if(this.user.preUserid){
          const _user = this.$store.getters['member/getUser'](this.user.preUserid);
          if(_user && _user.roleCode == ROLE_CODE.HOST){
            return true
          }else{
            return false
          }
        }else{
          return this.user.roleCode == ROLE_CODE.HOST
        }
      },
      isShowCoHost() {
        if(this.user.preUserid){
          const _user = this.$store.getters['member/getUser'](this.user.preUserid);
          if(_user && _user.roleCode == ROLE_CODE.CO_HOST){
            return true
          }else{
            return false
          }
        }else{
          return this.user.roleCode == ROLE_CODE.CO_HOST
        }
      },
      sharePaused() {
        if(!this.user.isShare) return false;

        if(!this.speakerView) return false;

        if(this.noSubscribeShare) return false;

        if(this.user.sharePaused) return true;
      },
      // 是否是自己在说话
      isSpeaking() {
        if(this.notHighlight) return false;
        const speakUser = this.$store.getters['member/getSpeakUser'];

        if(!speakUser) return false;
        if(this.user.isTopShare) return false;
        if(this.speakerView) return; // 演讲者视图不高亮

        return speakUser.userId == this.user.userId;

      },

      userMain() {
        return this.$store.getters["member/userMain"]
      },
      
      abbreviationUser() {
        return this.$store.getters["member/abbreviationUser"]
      },

      pageIndex() {
        return this.$store.state.member.pageIndex
      },

      // 是否是共享画面, 如果订阅共享，就不订阅人像
      checkSubscribeShare() {
        if(!this.user) return false;

        // 没开共享
        if(!this.user.isShare) return false;

        // 指明不订阅共享
        if(this.noSubscribeShare) return false;

        // 不在演讲者画面 不订阅共享
        if(!this.speakerView) return false;

        return true;

      },

      
      isFocusScreen() {
        return this.$store.state.meet.isFocusScreen;
      },

      userList() {
        return this.$store.state.member.userList
      },
      isExitUserShare() { // 存在用户共享
        return this.userList.find(i => i.isShare)
      },
    },

    mounted () {
      // loganLog('[videoItem] mounted remote user- ' + this.id);
      this.initRTCevent();
      this.startSubscribing()
    },
    beforeDestroy() {
      // loganLog('%c [videoItem] beforeDestroy user-' + this.id, 'color: red');
    },


    watch: {
      'user.userId': function(newVal,old) {
        if(newVal){
          this.startSubscribing()
        }
      },
      'user.isUseShiPin': function(newVal) {
        const isUseShiPin = newVal;

        if(isUseShiPin) {
          if(this.speakerView) { // 这是演讲者视图
            // 加定时器是因为 如果同时 remoteVideo, 会有上个未执行完bug
            setTimeout(() => {
              if(this.user.isShare && !this.noSubscribeShare && this.isFocusScreen == 0) return; //有在共享并且不是焦点，不执行startRemoteVideo
              this.startRemoteVideo()
            }, 400);
            return
          }

          // 不是当前页并且在宫格视图不订阅 
          if(this.page != this.pageIndex && !this.speakerView) {
            this.noRemoteVideo = true;
            return;
          } 
          if(this.user.isTopShare) return //topshare 不执行

          this.startRemoteVideo(); // 宫格视图
        } else {

          this.$parent.enlargeVideoShow && this.$parent.checkResumeVideoSize && this.$parent.checkResumeVideoSize(this.user,'video')
          if(this.speakerView) return; // 大图不执行 停止逻辑
          if(this.user.isTopShare) return //topshare 不执行
          this.stopRemoteVideo();          
        };
      },

      'user.isShare': function(newVal) {

        const isShare = newVal;

        // 停止订阅共享
        if(!isShare) {
          this.stopRemoteSharing();
          this.$parent.enlargeVideoShow && this.$parent.checkResumeVideoSize && this.$parent.checkResumeVideoSize(this.user,'share')
        }

        if(!this.speakerView) return; // 非演讲者视图 不关注其他isShare
        setTimeout(() => {
          if(
            isShare
            && !this.noSubscribeShare
            && this.isFocusScreen == 0
          ) { // 直接订阅共享 不关注是否 开启视频
            this.startRemoteSharing();
            return
          }

          // 如果这个人也开了视频 --> 订阅视频  ----- 加定时器是因为 如果关闭了 stopShare, 可能会有延迟
          if(this.user.isUseShiPin) {
            this.startRemoteVideo()
          };
        }, 400)
      },

      isFocusScreen: function(newVal) {
        const isFocusScreen = newVal;
        if(isFocusScreen){
          this.$store.commit('member/updatePageIndex', 0);
          this.$eventBus.$emit("resume-video-size"); // 收起全屏观看
          this.$parent.enlargeVideoShow && this.$parent.resumeVideoSize && this.$parent.resumeVideoSize()
          if(this.speakerView) { // 这是演讲者视图
            if(this.user.isUseShiPin){
              this.startRemoteVideo()
            }
            return
          }
          // 不是当前页并且在宫格视图不订阅 
          if(this.page != this.pageIndex && !this.speakerView) {
            this.noRemoteVideo = true;
            return;
          } 


          this.startRemoteVideo(); // 宫格视图
        } else { //取消焦点
          if(this.speakerView && !this.noSubscribeShare) { // 这是演讲者视图
            if(this.user.isShare){
              this.startRemoteSharing();
            }
            return
          }
          if(this.isExitUserShare){
            this.$store.commit('member/updatePageIndex', 0);
            this.$eventBus.$emit("resume-video-size"); // 收起全屏观看
          }
        }
      },

      // watch pageIndex
      pageIndex: function(newVal) {
        if(newVal == this.page) {
          loganLog(`[videoItem] videoItem == this.pageIndex`,this.user)

          if(this.isFocusScreen!= 0 && this.user.isTopShare){ // 有焦点有共享的情况
            this.noRemoteVideo = false;
            setTimeout(() => {
              this.startRemoteSharing()
            }, 50)
          } else if(this.user.isUseShiPin) setTimeout(() => {
            this.startRemoteVideo()
          }, 50) // 兼容快速排序变化 （上个刚停，立即再订阅 ）


        } else {

          loganLog(`[videoItem] videoItem != this.pageIndex`)
          if(!this.user || this.speakerView) return; // 横屏或者大图不做任何处理

          if(
            this.userMain
            && this.userMain.userId == this.user.userId // 自己在 userMain 不执行停止订阅
          ) {
            loganLog(`[videoItem] videoItem != this.pageIndex this.userMain is me, userId: ${this.user.userId}`);
            this.noRemoteVideo = true;
            return
          }

          if(
            this.abbreviationUser
            && this.abbreviationUser.userId == this.user.userId // 自己在 abbreviationUser 不执行停止订阅
          ) {
            loganLog(`[videoItem] videoItem != this.pageIndex this.abbreviationUser is me, userId: ${this.user.userId}`);
            this.noRemoteVideo = true;
            return
          }

          if(this.user.isUseShiPin) {
            loganLog(`[videoItem] videoItem != this.pageIndex stopRemoteVideo, userId: ${this.user.userId}`)
            this.noRemoteVideo = true;
            this.stopRemoteVideo();
          }


        }
      }

    },

    methods: {
      /**** -------------- 宫格视图的 远端 video ----------*********/
      startSubscribing(){
        if(!this.user) return;
        // 订阅共享画面
        if(this.isFocusScreen == 0){ //没有焦点的情况下
          if(
            this.user.isShare
            && this.speakerView
            && !this.noSubscribeShare
          ) {
            this.startRemoteSharing();
            return
          }
        }else{
          if(this.user.isTopShare || this.user.isCopyShare && this.$parent.enlargeVideoShow && !this.noSubscribeShare) {
            this.startRemoteSharing();
            return
          }
        }
        // 不是当前页不订阅
        if(this.page != this.pageIndex && !this.speakerView) {
          // loganLog('this.noRemoteVideo = true', JSON.stringify(this.user));
          if(this.user.isUseShiPin) this.noRemoteVideo = true;
          return;
        }

        if(this.user.isUseShiPin) setTimeout(() => {
          this.startRemoteVideo()
        }, 50) // 兼容快速排序变化 （上个刚停，立即再订阅 ）
      },
      async stopRemoteVideo() {
        loganLog(`[videoItem] stopRemoteVideo userId: ${this.user.userId}`)

        try {
          
          await this.sdk.rtc.stopRemoteVideo(this.user.userId);

          const videoEl = this.$el.querySelector('video');
          if(videoEl) {
            videoEl.srcObject = null;
          }
        } catch (error) {
          
        }

        
      },

      /****** ----------------- 远端共享画面 ----------------- *******/
      startRemoteSharing() {
        
        const operation = retry.operation({
          retries: 3,
          minTimeout: 0
        });
        this.$nextTick(() => {
          const { userId, } = this.user;
          const videoEl = this.$el.querySelector('video');
          this.status = 'loading'
          operation.attempt(async (currentAttempt) => {
            loganLog(`[videoItem] startRemoteShare retry次数-----${currentAttempt}, userId: ${userId}, el: ${videoEl}` );

            try {
              await this.sdk.rtc.startRemoteSharing(userId, videoEl);

              this.status = 'ok';


              videoEl.addEventListener('pause', () => {
                setTimeout(() => {
                  videoEl.play();
                }, 500);
              })

            } catch (error) {
              loganLog(`[videoItem] startRemoteShare error userId: ${userId}, ${JSON.stringify(error)}` );
              if (!operation.retry(new Error())) { // 已达到最大返回数量
                loganLog(`[videoItem] 订阅共享超过最大重试次数-----${currentAttempt}`)
              }

            }

          })


        })
      },

      stopRemoteSharing() {
        loganLog(`[videoItem] stopRemoteSharing userId: ${this.user.userId}`)
        try {
          this.sdk.rtc.stopRemoteSharing(this.user.userId);
        } catch (error) {
          // console.error('stopRemoteSharing error', error);
        }
      },


      // 带有重试的订阅
      startRemoteVideo () {

        const operation = retry.operation({
          retries: 3,
          minTimeout: 0
        });
        this.$nextTick(() => {

          const { userId } = this.user;
          let videoEl = this.$el.querySelector('video');

          this.status = 'loading';
          this.noRemoteVideo = false;

          operation.attempt(async (currentAttempt) => {
            loganLog(`[videoItem] startRemoteVideo retry次数-----${currentAttempt}, userId: ${userId}, ${videoEl}`);

            /**** --------- 真正的订阅逻辑 --------- ****/
            try {

              await this.sdk.rtc.startRemoteVideo(userId, videoEl);
              this.status = 'ok';

              videoEl.addEventListener('loadedmetadata', () => {
                console.log('[debug info] startRemoteSharing() | 视频关键帧装载完毕！ loadedmetadata')
                setTimeout(() => {
                  videoEl.play();
                }, 200);
              })

              videoEl.addEventListener('pause', () => {
                console.log('[debug info] startRemoteSharing() | 视频 被莫名暂停了！ paused')
                setTimeout(() => {
                  videoEl.play();
                }, 500);
              })

              // operation.stop();
            } catch (error) {

              if (!operation.retry(new Error())) { // 已达到最大返回数量
                console.log(`订阅视频超过最大重试次数-----${currentAttempt}`)
              }
            }


          })


        })

      },


      initRTCevent() {

        // 视频不稳定
        this.sdk.on('video-instable', (userId, share) => {
          loganLog(`连续几秒（5/10/15）未收到订阅的指定流的视频包, userId: ${userId}, share: ${share}`);

          if(userId != this.user.userId) return;
          if(share && !this.speakerView) return; // 共享的loading只显示 在大画面
          if(!share && this.user.isTopShare) return
          this.status = 'loading'
        })

        // 视频恢复稳定
        this.sdk.on('video-stable', (userId, share) => {
          loganLog(`重新订阅的指定流的视频包, userId: ${userId}, share: ${share}`);

          if(userId != this.user.userId) return;
          if(share && !this.speakerView) return; // 共享的只显示 在大画面
          if(!share && this.user.isTopShare) return
          this.status = 'ok'
        });

        // 不支持的编码能力
        this.sdk.on('codec-unsupport', (userId, share) => {
          loganLog(`[videoItem] 不支持的解码方式，userId: ${userId}, share: ${share}`);
          
          if(userId != this.user.userId) return;

          // 共享流
          if(share && this.checkSubscribeShare) this.status = 'codecUnsupport';

          // 非共享流
          if(!share && !this.checkSubscribeShare) this.status = 'codecUnsupport';
          
        });

      }

    },



}
</script>


<style lang="less" scoped>




  div {
    box-sizing: border-box;
  }

  .video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    background: #222;

    video {
      width: 100%;
      height: 100%;
      background: #222;

    }

    .user-info {
      position: absolute;
      display: flex;
      align-items: center;
      left: 0;
      bottom: 0;
      z-index: 9;
      transform: translateZ(0);

      height: 40px;

      background-color:rgba(0, 0, 0, .5);
      border-radius: 0px (8px) 0px 0px;
      padding-right: 8px;

      max-width:100%;



      svg {
        flex-shrink: 0;
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

    }

    .no-video-placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 8;

      display: flex;
      justify-content: center;
      align-items: center;

      .name {
        width: 128px;
        height: 128px;
        background: #1AB370;
        border-radius: 128px;

        text-align: center;
        line-height: 128px;

        font-size: 40px;
      }

      .avatar {
        width: 128px;
        height: 128px;
        border-radius: 100%;
      }
    }

    .speaking-highlight {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;

      border: (3px) solid #1AB370;
    }

    .van-loading {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .share-paused {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      color: #fff;
      background: #000;

      display: flex;
      justify-content: center;
      align-items: center;

    }



  }

</style>
