<template>
  <div ref="meetingControl">
  </div>
</template>

<script>
import { ROLE_CODE, CONTROL_COMMAND, LAYOUT_CONFIG } from "@/constant/index";
import { strMiddleEllipsis } from "@/utils/str"
import { isString } from '@/utils/tool';
import { loganLog } from "@/utils/log"
import dayjs from "dayjs";
import Logan from "logan-web";
import { reportLogUrl } from '@/config/index';
import { getUuid } from "@/utils/auth";

export default {
  data() {
     return {
     }
  },

  props: {
    
  },

  components: {
  },

  computed: {
    userList() { // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },

    selfInfo() {
      const userId = this.$route.params.userID;
      const roomId = this.$route.query.roomID;

      return this.$store.getters["member/getUser"](`${roomId}_${userId}`)
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    },

    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    }
  },
  created() {
    // 注册web-sdk改名监听事件
    this.initChangeNameEvent();
    //注册web-sdk会控（customCommand）监听事件
    this.initMeetingControlEvent()
  },
  
  methods: {
    notify(msg) {
      this.$notify({
          message: msg,
          color: '#FF4D4F',
          background: '#ffeded'
      })
    },
   
    //主持人请求解除静音确认框
    unMuteAudioConfirm() {
      this.$dialog.confirm({
        title: this.$t('login.tips'),
        message: this.$t('meeting.pleacenoVoice'),
        confirmButtonText: this.$t('meeting.audioUnMute'),
        cancelButtonText: this.$t('meeting.keepQuiet')
      }).then(async () => {
         await this.resumeAudioDialogHandle()
      })
    },

    /**
     * 在userList中获取主持人信息
     */
    setOldHostToUser() {
      // 将之前的老主持人重置为普通用户
      const oldHost = this.userList.find((i) => i.roleCode === ROLE_CODE.HOST) || {};

      if (oldHost.userId) {
        this.$store.commit("member/updateUser", {
          userId: oldHost.userId,
          roleCode: ROLE_CODE.USER,
        });

        //变为普通用户 举手提示消失
        this.$store.commit("meet/updateGlobalMeetState", {
          raisehandPopoverShow: false
        })
      }
    },

    /**
     * 用户通过弹窗主动解除静音
     */
    resumeAudioDialogHandle() {
      const selfId = this.$configs.peerId;

      try {
        //静音操作
        sdk.rtc.muteLocalAudio(false).then(()=>{
          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseHuaTong: true,
          });
        })
      } catch (error) {
        loganLog({
          msg: '用户通过弹窗主动解除静音',
          userId: selfId,
          userName: this.selfInfo.userName,
          error
        }, '会控异常|主动解除静音')
      }
    },

    /**
     * 全体静音操作
     */
    async _allMuteHandle(data, allowSelfUnmute) {
      // 全体静音：
      // 1、主持人操作，生效范围：在会场/新入会的——所有联席主持人（包括owner）+所有普通成员（包括owner）
      // 2、联席主持人操作，生效范围：在会场/新入会的——其他联席主持人（包括owner）+所有普通成员（包括owner）

      this.$store.commit("meet/updateGlobalMeetState", {
        allMuteState: 1,
        allowSelfUnmute
      });

      const selfId = this.$configs.peerId;
      let isRun = false

      if (this.isHost) { // 自己是主持人
        console.log("主持人不会被静音");
      } else if (this.isCoHost) { // 自己是联席
        if (selfId !== data.from.peerid) {
          // 操作人不是自己
          console.log("是联席，并且操作人不是是自己");
          isRun = true
        } else {
          console.log("是联席，并且操作人是自己");
        }
      } else { // 普通用户
        console.log("普通用户");
        isRun = true
      }


      if (isRun && this.selfInfo.isUseHuaTong) { // isRun为true并且非静音状态
        try {
          await this.sdk.rtc.muteLocalAudio(true) // 主动调用信令，执行静音操作

          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseHuaTong: false,
          });

          this.$toast(this.$t('meeting.allNoVoice'))
        } catch (error) {
          loganLog({
            msg: '全体静音操作',
            userId: selfId,
            userName: this.selfInfo.userName,
            error
          }, '会控异常|全体静音')
        }
      }
    },

    /*************** 会控回调监听 ***************** */
     /**
     * 注册web-sdk改名通知回调
     */
    initChangeNameEvent() {
      this.sdk.on("onDisplayNameChanged", (data) => {
        console.log("-----rtc changeName------");
        console.log(data);
        loganLog(data, '会控监听|改名')

        const { peerId, displayName } = data;

        this.$store.commit("member/updateUser", {
          userId: peerId,
          userName: displayName,
        })
      })
    },

    /**
     * 注册web-sdk会控通知回调
     */
    initMeetingControlEvent() {
      console.error("----会控监听调用--------");
      this.sdk.on("customCommand", (data) => {
        
        try {
          data.metadata = isString(data.metadata) ? JSON.parse(data.metadata) : data.metadata;
        } catch (error) {
          data.metadata = {};
        }

        console.error(data);
        loganLog("----会控监听回调--------",data)

        switch (data.command) {
          //主持人进入房间
          case CONTROL_COMMAND.HOST_JOIN_ROOM:
            this.handleHostJoinRoom(data);
            break;

          //自动选取主持人
          case CONTROL_COMMAND.AUTOMATICGRANT:
            this.handleAutomaticGrant(data);
            break;

          //收到改名
          case CONTROL_COMMAND.CHANGE_NAME:
            this.handleChangeName(data);
            break;
          
          //授权角色变更
          case CONTROL_COMMAND.GRANT:
            this.handleGrant(data);
            break;
 
          //转移主持人
          case CONTROL_COMMAND.TRANSFER_HOST:
            this.handleTransferHost(data);
            break;
          
          //收回主持人权限
          case CONTROL_COMMAND.RECOVER_HOST_PERMISSION:
            this.handleRecoverHostPermission(data);
            break;

          //收到静音操作
          case CONTROL_COMMAND.SINGLE_MUTE:
            this.handleSingleMute(data);
            break;
          
          //取消静音操作
          case CONTROL_COMMAND.SINGLE_UNMUTE:
            this.handleSingleUnMute(data);
            break;
          
          //停止共享
          case CONTROL_COMMAND.STOP_SHARE:
            this.handleStopShare(data);
            break;
          
          //收到关闭视频指令
          case CONTROL_COMMAND.SHIELD_VIDEOS:
            this.handleShieldVideos(data);
            break;

          //录制相关
          // 收到信令参会人开始录制
          case CONTROL_COMMAND.START_RECORD:
            this.handleRecord(data, 'isRecord', true);
            this.handleRecord(data, 'recordPaused', false);
            break;
          // 收到信令停止参会人录制
          case CONTROL_COMMAND.STOP_RECORD:
            this.handleRecord(data, 'isRecord', false);
            this.handleRecord(data, 'recordPaused', false);
            break;
        // 收到信令暂停录制
          case CONTROL_COMMAND.PAUSE_RECORD:
            this.handleRecord(data, 'isRecord', true);
            this.handleRecord(data, 'recordPaused', true);
            break;
        // 收到信令恢复录制
          case CONTROL_COMMAND.RESUME_RECORD:
            this.handleRecord(data, 'isRecord', true);
            this.handleRecord(data, 'recordPaused', false);
            break;

          //收到踢出自己指令
          //TODO
          case CONTROL_COMMAND.REMOVE_SELF:
            this.handleRemoveSelf(data);
            break;

          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.REMOVE_USER:
          //   this.handleRemoveUser(data);
          //   break;
          
          //举手
          case CONTROL_COMMAND.RAISE_HAND:
            this.handleRaiseHand(data);
            break;
          
          //手放下
          case CONTROL_COMMAND.SINGLE_HAND_DOWN:
            this.handleDownHand(data);
            break;
          

          //所有手放下
          case CONTROL_COMMAND.ALL_HAND_DOWN:
            this.handleAllHandDown(data);
            break;

          /* ------------全局会议状态处理---------------- */
          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.END_MEET:
          //   this.handleEndMeet();
          //   break;

          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.FORCE_END_MEET:
          //   this.handleForceEndMeet();
          //   break;
          
          //锁定会议
          case CONTROL_COMMAND.LOCK_CONFERENCE:
            this.handleLockConference(data);
            break;
          
          //解锁会议
          case CONTROL_COMMAND.UNLOCK_CONFERENCE:
            this.handleUnLockConference(data);
            break;
          
          //允许成员自我解除静音
          case CONTROL_COMMAND.ALLOW_SELF_UNMUTE:
            this.handleAllowSelfUnmute(data);
            break;
          
          //禁止成员自我解除静音
          case CONTROL_COMMAND.FORBID_SELF_UNMUTE:
            this.handleForbidSelfUnmute(data);
            break;
          
          //成员加入会议时开启播放提示音
          case CONTROL_COMMAND.OPEN_PLAY_TIPS:
            this.handleOpenPlayTipse(data);
            break;
          
          //成员加入会议时关闭播放提示音
          case CONTROL_COMMAND.CLOSE_PLAY_TIPS:
            this.handleClosePlayTipse(data);
            break;
          
          // 成员加入会议开启静音
          case CONTROL_COMMAND.OPEN_MUTE_JOIN_MEETING:
            this.handleOpenMuteJoinMeeting(data);
            break;
          
          //成员加入会议关闭静音
          case CONTROL_COMMAND.CLOSE_MUTE_JOIN_MEETING:
            this.handleCloseMuteJoinMeeting(data);
            break;
          
          //全体强制静音
          case CONTROL_COMMAND.ALL_FORCE_MUTE:
            this.handleAllForceMute(data);
            break;

          //全体非强制静音
          case CONTROL_COMMAND.ALL_UNFORCE_MUTE:
            this.handleAllUnForceMute(data);
            break;
          
          //全体解除静音
          case CONTROL_COMMAND.ALL_UNMUTE:
            this.handleAllUnMute(data);
            break;

          //
          case CONTROL_COMMAND.SHARE_PERMISSIONS_ALL:
            this.handleSharePermissionsForAll(data);
            break;

          case CONTROL_COMMAND.SHARE_PERMISSIONS_HOST:
            this.handleSharePermissionsForHost(data);
            break;
        // 所有人都可以录制
          case CONTROL_COMMAND.RECORD_PERMISSIONS_ALL:
            this.handleRecordPermissionForAll(data);
            break;
        // 仅主持人录制
          case CONTROL_COMMAND.RECORD_PERMISSIONS_HOST:
            this.handleRecordPermissionForHost(data);
            break;

          case CONTROL_COMMAND.SCHEDULE_PERMISSIONS_ALL:
            this.handleSchedulePermissionForALL(data);
            break;

          case CONTROL_COMMAND.SCHEDULE_PERMISSIONS_HOST:
            this.handleSchedulePermissionForHost(data);
            break;
          
          //免费时长倒计时 
          //TODO 后面补
          case CONTROL_COMMAND.MATURITY_NOTICE:
            this.handleMaturityNotice(data); 
            break;

          //申请成为主持人
          case CONTROL_COMMAND.APPLY_HOST:
            this.handleApplyHost(data);
            break;
            case CONTROL_COMMAND.START_CLOUDRECORD: //开始云录制
            this.handleRecordFn(data,10);
            break;

          case CONTROL_COMMAND.START_BACK_CLOUDRECORD: //开始云录制准备好了
            this.handleRecordFn(data,11);
            break;
      
          case CONTROL_COMMAND.PAUSE_CLOUDRECORD: //暂停云录制
            this.handleRecordFn(data,20);
            break;
          case CONTROL_COMMAND.RESUME_CLOUDRECORD: //恢复云录制
            this.handleRecordFn(data,30);
            break;
          case CONTROL_COMMAND.RESUME_BACK_CLOUDRECORD: //恢复云录制准备好了
            this.handleRecordFn(data,31);
            break;
          case CONTROL_COMMAND.STOP_CLOUDRECORD: //结束云录制
            this.handleRecordFn(data,40);
            break;

          case CONTROL_COMMAND.OPEN_VIDEOS: //请求参会人开启视频
            this.handleOpenVideo(data);
            break;

          case CONTROL_COMMAND.SET_FOCUS: //收到焦点
            this.handleFocus(1,data);
            break;

          case CONTROL_COMMAND.CANCE_FOCUS: //收到取消焦点
            this.handleFocus(0,data);
            break;

          case CONTROL_COMMAND.INVITATION_UPLOADLOG: //请求参会人上传日志
            this.handleUploadLog(data);
            break;
          default:
            console.log("unknow command");
        }
      })
    },

    /*************** 会控回调处理 ***************** */
    /**
     * 主持人进入房间
     */
    handleHostJoinRoom(data) {
      const { peerid } = data.to;

      console.error('主持人进来了')

      // let isRun = true

      // if (peerid === this.$configs.peerId) { // 本人
      //   if (this.selfInfo.userId) { // 存在，那说明时序没有问题
      //     console.error('时序没有问题---------')
      //   } else {
      //     window._localRoleCode = ROLE_CODE.HOST
      //     isRun = false
      //     console.error('时序错误---------')
      //   }
      // }

      // if (isRun) {
      //   this.$store.commit("member/updateUser", {
      //     userId: peerid,
      //     roleCode: ROLE_CODE.HOST,
      //   })
      // }

      console.error('---------更新成主持人---------');

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST,
        });
      // })

      //当前用户被选成主持人
      const selfId = this.$configs.peerId;
      if (selfId === peerid) { 
        //变为主持人 举手提示出现
        this.$store.commit("meet/updateGlobalMeetState", {
          raisehandPopoverShow: true
        })
      }
    },
    handleRecordFn(data,type) {
      console.log(`--嘎嘎个收到云录制信令---type:${type}`);
      this.$store.commit("meet/updateGlobalMeetState", {
        cloudRecorState: type
      })
    },

    // 收到开启视频请求
    async handleOpenVideo(data){
      const selfId = this.$configs.peerId;
      if(selfId === data.to.peerid){
        this.$dialog.confirm({
          title:'请求打开您的摄像头',
          confirmButtonText: '同意',
          cancelButtonText:'不同意',
          center: true
        }).then(() => {
          this.$eventBus.$emit('start-local-video');
        }).catch(()=>{
          console.error('不同意')
        })
      }
    },

    /**
     * 收到焦点事件
    */
    async handleFocus(type,data) {
      console.log("--handleFocus---");
      console.log(type,data);
      let _id = type == 0 ? 0 : data.to.peerid
      this.$store.commit("meet/updateGlobalMeetState", {
        isFocusScreen: _id
      })
    },

    // 收到上传日志通知
    async handleUploadLog(data){
      console.error('收到上传日志通知')
      const { roomId, peerId, userId } = this.$configs
      if(peerId === data.to.peerid){
        // this.$toast(this.$t("meeting.loging"));
          const currentTimestamp = new Date().getTime();
          const endDate = dayjs(currentTimestamp).format("YYYY-MM-DD");
          const startDate = dayjs(currentTimestamp)
            .subtract(1, "day")
            .format("YYYY-MM-DD");
          try {
            let res = await Logan.report({
              reportUrl: reportLogUrl,
              deviceId: "wechat_" + getUuid(),
              fromDayString: startDate,
              toDayString: endDate,
              customInfo: JSON.stringify({
                peerId: peerId,
                roomId: roomId,
                userId: userId,
                userName: localStorage.getItem('displayNameKey'),
                conferenceNo: localStorage.getItem('localConferenceNoKey')
              }),
              webSource: "wechat",
            });
            for (const key in res) {
              if (Object.hasOwnProperty.call(res, key)) {
                const element = res[key];
                if (element.msg !== "Report succ") {
                  if (element.msg === "No log exists") {
                    // this.$dialog.alert({
                    //   confirmButtonColor: "#000",
                    //   message: this.$t("meeting.noLog"),
                    // });
                  }
                } else {
                  // this.$dialog.alert({
                  //   message: this.$t("meeting.logSuccess"),
                  // });
                  console.error('上传日志成功')
                }
              }
            }
            console.log("上传日志", res);
          } catch (error) {
            console.error("上传日志失败", error);
            // this.$dialog.alert({
            //   message: this.$t("meeting.logFail"),
            // });
          }


      }
    },
    /**
     * 自动选取主持人
     */
    async handleAutomaticGrant(data) {
      console.log("------automaticGrant-------");
      console.log(data);

      const { peerid } = data.to;

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST,
        })
      // })

      //当前用户被选成主持人
      const selfId = this.$configs.peerId;
      if (selfId === peerid) { 
        //变为主持人 举手提示出现
        this.$store.commit("meet/updateGlobalMeetState", {
          raisehandPopoverShow: true
        })
      }
    },

    /**
     * 收到改名
     */
    async handleChangeName(data) {
      const selfId = this.$configs.peerId;
      const { userName } = data.metadata;

      if (selfId === data.to.peerid) {
        // 要更改名字的为当前用户
        try {
          // 调用RTC改名
          sdk.rtc.changeLocalDisplayName(userName);
        } catch (error) {
          loganLog(error, '会控异常|rtc异常|监听回调异常|改名')
        }
        
        try {
          // im改名
          sdk.im.updateNickAndAvatar({
            nickname: userName,
          });
        } catch (error) {
          loganLog(error, '会控异常|im异常|监听回调异常|改名')
        }
      }
    },

    /**
     * 授权角色变更
     */
    handleGrant(data) {
      console.log("--handleGrant---");
      console.log(data);

      const selfId = this.$configs.peerId;
      const { peerid } = data.to;
      const { roleCode } = data.metadata;

      if (peerid === selfId) { // 角色变更的是自己
        if (Number(roleCode) === ROLE_CODE.CO_HOST) {
          this.$toast(this.$t('meeting.becomeGoHost'))

          //变为联席主持人 举手提示出现
          this.$store.commit("meet/updateGlobalMeetState", {
            raisehandPopoverShow: true
          })
        } else if (Number(roleCode) === ROLE_CODE.USER) {
          if (this.isCoHost) { // 当前为联席主持人，角色变更为普通用户
            this.$toast(this.$t('meeting.backModerator'))

            //变为普通用户 举手提示消失
            this.$store.commit("meet/updateGlobalMeetState", {
              raisehandPopoverShow: false
            })
          }
        }
      }

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: Number(roleCode)
        })
      // })
    },


    /**
     * 转移主持人
     */
    handleTransferHost(data) {
      console.log("--handleTransferHost---");
      console.log(data);

      // 将之前的老主持人重置为普通用户
      this.setOldHostToUser();

      const selfId = this.$configs.peerId;
      if (selfId === data.to.peerid) {
        const fromUserInfo = this.userList.find((i) => i.userId === data.from.peerid)

        const commonTipText = `${strMiddleEllipsis(fromUserInfo.userName, 10, 3, 2)}` + this.$t('meeting.giveYou');
        this.$toast(commonTipText)
        //变为主持人 举手提示出现
        this.$store.commit("meet/updateGlobalMeetState", {
          raisehandPopoverShow: true
        })
      }

      // this._handleAddUser(data.to.peerid, () => {

        this.$store.commit("member/updateUser", {
          userId: data.to.peerid,
          roleCode: ROLE_CODE.HOST,
        });
      // })
    },
    

     /**
     * 收回主持人权限
     */
    handleRecoverHostPermission(data) {
      console.log("--handleRecoverHostPermission---");
      console.log(data);

      // 将之前的老主持人重置为普通用户
      this.setOldHostToUser();

      const { peerid } = data.from; // 发起收回主持人的peerId

      console.log(peerid);

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST
        })
      // })
    },


     /**
     * 收到静音操作
     */
    async handleSingleMute(data) {
      console.log("--handleSingleMute---");
      console.log(data);

      const selfId = this.$configs.peerId;
      if (selfId === data.to.peerid) {
        try {
          // 自己收到主持人的静音操作
          await this.sdk.rtc.muteLocalAudio(true) // 主动调用信令，执行静音操作

          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseHuaTong: false,
          })
          this.$toast(this.$t('meeting.mainNoVoice'))
        } catch (error) { 
          loganLog({
            msg: '收到会控的静音操作',
            userId: selfId,
            userName: this.selfInfo.userName,
            error
          }, '会控异常|监听回调异常|静音')
        }
      }
    },


    /**
     * 取消静音操作，需要弹出对话框让用户去选择
     */
    handleSingleUnMute(data) {
      console.log("--handleSingleUnMute---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 自己收到主持人的取消静音操作
        this.unMuteAudioConfirm()
      }
    },


     /**
     * 停止共享
     */
    handleStopShare(data) {
      console.log("--handleStopShare---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) { // 本人收到主持人的关闭分享操作
        try {
          // 执行后会触发分享结束的回调
          this.sdk.rtc.stopScreenSharing()
        } catch (error) {
          loganLog(error, '会控异常|rtc异常|监听回调异常|停止共享')
        }

        if (selfId === data.from.peerid) { // 自己操作自己
          console.error('自己操作自己')
          return
        } else {
          // const fromUser = this.userList.find(i => i.userId === data.from.peerid)

          // let targetName = '主持人'
          // if (fromUser && (fromUser.roleCode === ROLE_CODE.CO_HOST)) {
          //   targetName = '联席主持人'
          // }

          const commonTipText = this.$t('meeting.mainStopShare')
          this.$toast(commonTipText)
        }
      }
    },

     /**
     * 收到关闭视频的会控指令
     */
    handleShieldVideos(data) {
      console.log("--handleShieldVideos---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 自己收到主持人的关闭视频操作
        try {
          this.sdk.rtc.stopLocalVideo(); // 主动调用信令，执行关闭视频操作
        } catch (error) {
          loganLog(error, '会控异常|rtc异常|监听回调异常|停止视频')
        }
        

        this.$store.commit("member/updateUser", {
          userId: selfId,
          isUseShiPin: false,
        });

        this.$toast(this.$t('meeting.closeVideoByHost'))
      }
    },

    
    /**
     * 录制相关
     */
    async handleRecord(data, key, value) {
      console.log("--record---");
      console.log(data);

      const { peerid } = data.from

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          [key]: value
        })
      // })
    },

    /**
     * 收到踢出自己命令
     */
    async handleRemoveSelf(data) {
      console.log("--handleRemoveSelf---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 已经在其他设备上登录
        //TODO
        //this.exitMeetByTimer(this.$t('meeting.noMembership'));

        // 发送信息到 Exit.vue
        this.$eventBus.$emit('remove-self')
      }
    },

    /**
     * 举手
     */
    async handleRaiseHand(data) {
      console.log("--handleRaiseHand---");
      console.log(data)

      const { peerid } = data.from;

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          isRaiseHand: true,
        })
      // })
    },

    /**
     * 手放下
     */
    async handleDownHand(data) {
      console.log("--handleDownHand---");
      console.log(data);

      const { peerid } = data.to;

      this.$store.commit("member/updateUser", {
        userId: peerid,
        isRaiseHand: false,
      });
    },

    /**
     * 所有手放下
     */
    async handleAllHandDown(data) {
      console.log("--handleAllHandDown---");
      console.log(data);
      this.userList.forEach((item) => {
        const { userId } = item;

        this.$store.commit("member/updateUser", {
          userId,
          isRaiseHand: false,
        });
      });
    },


    /**
     * 锁定会议
     */
    handleLockConference(data) {
      console.error("锁定--")
      console.log(data)

      this.$store.commit("meet/updateGlobalMeetState", {
        lockedState: 1
      })

      const { peerid } = data.from
      if (peerid === this.$configs.peerId) {
        this.$toast(this.$t('meeting.lockMeeting'))
      }
    },

    
    /**
     * 解锁会议
     */
    handleUnLockConference(data) {
      console.log("解锁--");
      this.$store.commit("meet/updateGlobalMeetState", {
        lockedState: 0,
      });

      const { peerid } = data.from
      if (peerid === this.$configs.peerId) {
        this.$toast(this.$t('meeting.okMeet'))
      }
    },

    /**
     * 允许成员自我解除静音
     */
    handleAllowSelfUnmute(data) {
      console.log("---handleAllowSelfUnmute--");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allowSelfUnmute: 1,
      });
    },

    /**
     * 禁止成员自我解除静音
     */
    handleForbidSelfUnmute(data) {
      console.log("--handleForbidSelfUnmute---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allowSelfUnmute: 0,
      });
    },

    /**
     * 成员加入会议时开启播放提示音
     */
    handleOpenPlayTipse(data) {
      console.log("--handleOpenPlayTipse---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        playTips: 1,
      });
    },

    /**
     * 成员加入会议时关闭播放提示音
     */
    handleClosePlayTipse(data) {
      console.log("---handleClosePlayTipse--");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        playTips: 0,
      });
    },

    /**
     * 成员加入会议开启静音
     */
    handleOpenMuteJoinMeeting(data) {
      console.log("--handleOpenMuteJoinMeeting---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        muteJoinMeeting: 1,
      });
    },

    /**
     * 成员加入会议关闭静音
     */
    handleCloseMuteJoinMeeting(data) {
      console.log("--handleCloseMuteJoinMeeting---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        muteJoinMeeting: 0,
      });
    },

    /**
     * 全体强制静音
     *
     */
    handleAllForceMute(data) {
      console.log("--handleAllForceMute---");
      console.log(data);

      this._allMuteHandle(data, 0);
    },

    /**
     * 全体非强制静音
     */
    handleAllUnForceMute(data) {
      console.log("--handleAllUnForceMute---");
      console.log(data);

      this._allMuteHandle(data, 1);
    },


    /**
     * 全体解除静音
     * // 全局的静音状态  0：全体解除静音  1：全体静音
     */
    handleAllUnMute(data) {
      console.log("--handleAllUnMute---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allMuteState: 0,
      });

      // 主持人操作，对所有的联席和普通成员生效
      // 联席主持人操作，对其他联席和普通成员生效
      if (data.from.peerid === this.selfInfo.userId) { // 操作人为自己，此时不做任何操作
        return
      } else { // 操作其他人
        if (this.isHost) { // 如果自己是主持人，则不做任何操作
          return
        } else {
          if (!this.selfInfo.isUseHuaTong) {
            this.unMuteAudioConfirm()
          }
        }
      }

      // if (!this.isHost) {
      //   // 如果自己为主持人，则不处理
      //   if (!this.selfInfo.isUseHuaTong) {
      //     this.unMuteAudioDialog = true;
      //   }
      // }
    },

    
     /**
     *
     */
    handleSharePermissionsForAll(data) {
      console.log("--handleSharePermissionsForAll---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        sharePermission: 0,
      });
    },

    /**
     *
     */
    handleSharePermissionsForHost(data) {
      console.log("--handleSharePermissionsForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        sharePermission: 1,
      });
    },

    /**
     *
     */
    handleRecordPermissionForAll(data) {
      console.log("--handleRecordPermissionForAll---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        recordPermission: 0,
      });
    },

    /**
     *
     */
    handleRecordPermissionForHost(data) {
      console.log("--handleRecordPermissionForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        recordPermission: 1,
      });
    },

    /**
     *
     */
    handleSchedulePermissionForALL(data) {
      // console.log("--handleSchedulePermissionForALL---");
      // console.log(data);

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   agendaPermission: 0,
      // });
    },

    /**
     *
     */
    handleSchedulePermissionForHost(data) {
      // console.log("--handleSchedulePermissionForHost---");
      // console.log(data);

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   agendaPermission: 1,
      // });
    },

    /**
     * 免费时长倒计时提示
     */
    async handleMaturityNotice(data) {
      console.log("--handleMaturityNotice---");
      const { remainingTime } = data.metadata;
      this.$eventBus.$emit('maturity', remainingTime); // 透传到 header 组件
    },


    /**
     * 申请成为主持人
     */
    handleApplyHost(data) {
      console.log("--handleApplyHost---");
      console.log(data);

      // this._handleAddUser(data.from.peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: data.from.peerid,
          roleCode: ROLE_CODE.HOST
        })
      // })

      if (data.from.peerid === this.$configs.peerId) {
        this.$toast(this.$t('meeting.becomeMain'))
      }
    },

    



  }
};
</script>

<style lang="less" scoped>
</style>
