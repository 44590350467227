import axios from 'axios'
import qs from 'qs'
import { getClientId, getClientSecret, getRefreshToken, getUuid, getAcceptLanguage, getConferenceToken, getChannelToken } from "@/utils/auth"
import { meetServerUrl, middleServerUr,workBenchServerUrl, consoleUrl } from '@/config/index'
import { oauthLogin } from '@/api/meet'
import { loganLog } from "@/utils/log"
import bus from '@/utils/bus.js'

const whiteErrorlist = [60005, 60006, 60010, 60011, 60012]
const goBackCodeList = [60034, 60007, 60008, 60027, 60029]
const loginFailCodeList = [500, 501, 505, 506]

// create an axios instance
const Request = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
})

// request interceptor
Request.interceptors.request.use(
  config => {

    const conferenceToken = getConferenceToken()
    const channelToken = getChannelToken()

    let conferenceNo = ''
    const searchStr = window.location.search
    if (searchStr) {
      const parseQuery = qs.parse(searchStr, { ignoreQueryPrefix: true })
      conferenceNo = parseQuery.conferenceNo
    }

    // do something before request is sent
    config.headers['Accept-Language'] = getAcceptLanguage() // 多语言
    // config.headers["Authorization"] = getToken() ? `Bearer ${getToken()}` : ''
    config.headers["Authorization"] = ''


    config.headers["deviceId"] = getUuid() || ''

    config.headers["X-Conference-Token"] = conferenceToken || ''
    config.headers["X-Channel-Token"] = channelToken || ''
    config.headers["X-Conference-No"] = conferenceNo || ''

    /**
     * /middle-auth: 中台api
     * 其他为会议服务api
     */
    // const url = config.url
    // if (url.indexOf('/middle-auth') > -1) {
    //   config.url = middleServerUrl + url
    // } else {
    //   config.url = meetServerUrl + url
    // }

    const url = config.url

    if (url.indexOf('/middle-auth') > -1) {
      //包含/middle-auth
      config.url = middleServerUrl + url
    } else if(url.indexOf('/doctorapi') > -1){
      config.url = workBenchServerUrl + url
    } else if(url.indexOf('/sensitive/words') > -1){
      config.url = consoleUrl + url
    } else {
      config.url = meetServerUrl + url
    }


    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)


let isRefreshing = false
let subscribers = []

function onAccessTokenFetched(newToken) {
  subscribers.forEach((callback) => {
    callback(newToken)
  })
  subscribers = []
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

// response interceptor
Request.interceptors.response.use(
  response => {
    
    const resData = response.data
    const { url, isBlobType } = response.config
    
    // 优化，没有token的情况
    if (resData.code === 401 && !url.includes('login/oauth2/token')) {

      // console.log('触发了401--------')

      if (!isRefreshing) {
        isRefreshing = true

        // 将刷新token的方法放在vuex中处理了, 可见下面区块代码
        oauthLogin({
          grant_type: 'refresh_token',
          client_id: getClientId(),
          client_secret: getClientSecret(),
          refresh_token: getRefreshToken()
        }).then((res) => {
          // setToken(res.accessToken)
          onAccessTokenFetched(res.accessToken)
          isRefreshing = false
        }).catch(() => {
          // 刷新token报错了
          console.error('杠杆刷新token失败了---')
          isRefreshing = false

          // vm.$store.commit("meet/updateGlobalMeetState", {
          //   meetDialogInfo: {
          //     isGoLogin: true,
          //     showClose: false,
          //     commonText: vm.$t('login.tokenTime')
          //   }
          // })
          return Promise.reject(response.data)
        })
      }


      // 将其他接口缓存起来 -- 这个Promise函数很关键
      const retryOriginalRequest = new Promise((resolve) => {
        // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态, 
        // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口, 
        // 此时, Promise的状态就会变成resolve
        addSubscriber((newToken) => {
          // console.log('即将重新发起请求---')

          response.config.headers.Authorization = `Bearer ${newToken}`;

          // if (url.indexOf('/middle-auth') > -1) {
          //   // console.log('命中中台---');
          //   response.config.url = response.config.url.replace(middleServerUrl, '')
          // } else {
          //   // console.log('命中会议api---')
          //   response.config.url = response.config.url.replace(meetServerUrl, '')
          // }
          
          if (url.indexOf('/middle-auth') > -1) {
            // console.log('命中中台---');
            response.config.url = response.config.url.replace(middleServerUrl, '')
          }else if(url.indexOf('/doctorapi') > -1){
            response.config.url = response.config.url.replace(workBenchServerUrl, '')
          }else {
            // console.log('命中会议api---')
            response.config.url = response.config.url.replace(meetServerUrl, '')
          }


          // console.log(response.config)

          // 用重新封装的config去请求, 就会将重新请求后的返回
          resolve(Request(response.config))
        });
      });

      return retryOriginalRequest;
    }

    if (isBlobType) {
      return resData
    }
    
    if (resData.code == 200) { // 请求成功
      loganLog(`Request请求成功----url:${url}-data: ${JSON.stringify(resData)}`)
      return resData.data
    } else { // 请求失败
      loganLog(`Request请求错误----url:${url}-data: ${JSON.stringify(resData)}`)
      // bus.$emit('request-error', resData)
      let isShowDialog = false

      const meetDialogInfo = {
        tips: '',
        commonText: '',

        isGoIndex: false,
        isGoLogin: false,
        showClose: true,
        ensureText: '',

        conferenceNo: '',
        title: ''
      }

      // if (whiteErrorlist.indexOf(resData.code) > -1) { // 白名单，不做通用处理
      //   console.error('不做通用处理, 交给下流节点处理')
      // } else if (resData.code === 60002) { // 会议号不存在
      //   if (vm.$route.name === 'meeting') { // 会中触发，显示弹窗
      //     isShowDialog = true

      //     meetDialogInfo.isGoIndex = true
      //     meetDialogInfo.showClose = false
      //     meetDialogInfo.commonText = resData.msg
      //   } else { // 其他情况，触发totast
      //     vm.$totast({
      //       message: resData.msg
      //     })
      //   }
      // } else if (goBackCodeList.indexOf(resData.code) > -1) { // 回到首页
      //   isShowDialog = true

      //   meetDialogInfo.isGoIndex = true
      //   meetDialogInfo.showClose = false
      //   meetDialogInfo.commonText = resData.msg
      // } else if (resData.code === 401) { // Token过期了
      //   isShowDialog = true
      //   console.log('token过期了-----')
      //   /**
      //    * 如果cookie中token不存在，则跳转到登录页，否则的话触发refresh token
      //    */

      //   meetDialogInfo.isGoLogin = true
      //   meetDialogInfo.showClose = false
      //   meetDialogInfo.commonText = vm.$t('login.tokenTime')
      // } else if (loginFailCodeList.indexOf(resData.code) > -1) {
      //   isShowDialog = true

      //   if (url.includes('login/oauth2/token')) { // 登录接口
      //     meetDialogInfo.tips = vm.$t('login.loginFailed')
      //     meetDialogInfo.commonText = resData.msg
      //   } else if (url.includes('conference/reconnection')) { // reconnection接口，命中后需要返回上一级

      //     meetDialogInfo.commonText = resData.msg
      //     meetDialogInfo.isGoIndex = true
      //   } else {
      //     meetDialogInfo.commonText = resData.msg
      //   }
      // } else { // 其余情况，给一个通用的弹窗提示
      //   console.error('通用错误---')
      //   isShowDialog = true

      //   meetDialogInfo.commonText = resData.msg
      // }

      // if (isShowDialog) {
      //   vm.$store.commit("meet/updateGlobalMeetState", {
      //     meetDialogInfo
      //   })
      // }

      return Promise.reject(response.data)
    }
  },
  error => {
    // const tips = vm.$t('login.tips')
    // const describe  = error.message? `错误信息:${error.message}` :  error.msg? `错误信息:${error.msg}` : vm.$t('login.netFail')
    // const commonText  = error.code?`错误码:${error.code}`: ''


    const config = error.config || {}

    //todo 加sentry

    if (config.url && config.url.includes('ybmeet-conference/conference/reconnection')) {
      return Promise.reject(error)
    }

    // vm.$store.commit("meet/updateGlobalMeetState", {
    //   meetDialogInfo: {
    //     tips,
    //     describe,
    //     commonText
    //   }
    // })

    return Promise.reject(error)
  }
)

export default Request