<template>
  <!-- 聊天界面 -->
    <van-popup
      :value="value"
      @input="val => this.$emit('input', val)"
      class="chat-pop"
      :class="{ 'iosfocus': changeSize }"
      :get-container="getContainer"
      round
      :lock-scroll="true"
      :safe-area-inset-bottom="true"
      close-icon-position="top-right"
      position="bottom" >
      <div class='popup_content'>
        <div class='title' @click="emojClose">
          <my-icon class='icon_style' iconName="icon-jiantou_zuo_48" fontSize="36" @click="chatClose"></my-icon>
          {{$t('meeting.chatList')}}
        </div>
        <div id='message-send-pop-wrapper' ref="message-list" @click="emojClose" @scroll="onScroll">
          <!-- 消息部分 -->
          <div class="message-list">
            <div class="message-wrapper" :class="{'and-focus-wrapper' : isFocus && !isIOS }" v-for="(message, index) in currentMessageList" :key="index" :id="message.id">
              <div :class="message['uid'] === userID ? 'message-box':'message-box-other'">
                <div class="message-item" v-if="textMsg(message.type)">
                  <div class="message-nick-box">
                    <!-- msgType消息类型，0是单聊，2是群发消息 -->
                    <p v-if="message.msgType !== 0">
                      <span v-if="message['uid'] === userID">
                        <!-- 本人发送消息 -->
                        <span class="message-nick">{{ message['timestamp'] | formatdate }}</span>
                        <span class="message-date">{{getUserName(message)}} 发给所有人</span>
                      </span>
                      <span v-else>
                        <!-- 接收消息 -->
                        <span class="message-nick">{{ getUserName(message) }}</span>
                        <span class="message-date">{{message['timestamp'] | formatdate}}</span>
                      </span>
                    </p>
                  </div>

                  <div class="message-container">
                    <message-status-icon v-if="message.status === 'fail'" :message="message" @reSend="onResend">
                    </message-status-icon>
                    <div class="triangle"></div>
                    <template v-for="(item, index) in contentList(message.payload.text)">
                      <span :key="index" class="message-text" v-if="item.name === 'text'" v-html="item.text"></span>
                      <img v-else-if="item.name === 'img'" :src="require(`@/assets/emotion/${item.src}`)" width="20px"
                        height="20px" :key="index" />
                    </template>
                  </div>
                </div>
                <div class="message-item" v-if="picMsg(message.type)">
                  <div class="message-nick-box">
                    <p v-if="message.msgType !== 0" class="message-nick">
                      {{ getUserName(message) }}
                      <span class="message-date">{{message['timestamp'] | formatdate}}</span>
                    </p>
                    <div v-else class="private-message-nick">
                      <p v-if="message['uid'] === userID">
                        <span class="nick-info">{{$t('meeting.me')}}</span>{{$t('meeting.sendTo')}}<span class="nick-info">{{ message['toUserNick'] }}</span> <span
                          class="p-txt rubik-medium">{{$t('meeting.PrivateChat')}}</span>
                      </p>

                      <p v-else>
                        <span class="nick-info">{{ message['nick'] }}</span>{{$t('meeting.sendTo')}}<span class="nick-info">{{$t('meeting.me')}}</span> <span
                          class="p-txt rubik-medium">{{$t('meeting.PrivateChat')}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="pic-container">
                    <!--
                    <div v-html="message.payload.text"/>
                    -->
                    <van-image class="imgMaxHeight" @click="imagepreview(message.payload.text)" :src="message.payload.text" :fit="'contain'"
                        :preview-src-list="[message.payload.text]"/>
                    <message-status-icon v-if="message.status === 'fail'" :message="message"
                                        @reSend="onResend"></message-status-icon>
                  </div>

                </div>
                <div class="tip-leave" v-if="leaveMsg(message.type)">
                  <span>{{ getGroupTipContent(message) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
         <!-- 发送部分 -->
         <div class="send-header-bar" :style="isFocus ? 'position:fixed; bottom: 0;': ''" scroll="no">
          <div class="send-header-button">
                <van-form @submit.prevent="sendTextMessage('page')" action="javascript:return true">
                  <!-- <div class="input-box"> 
                    </div> -->
                    <van-field
                      class="chat-input input-box"
                      v-model.trim='chatContent'
                      @blur="clickBlur($event)"
                      @focus='clickFocus($event)'
                      @input="validChat($event)"
                      @keyup.enter.native="keyupValid($event)"
                      :autofacus="autofacus"
                      enterkeyhint="send"
                      rows="1"
                      cols="1"
                      fixed="true"
                      autosize
                      type="textarea"
                      ref="chatInput"
                      :placeholder="$t('meeting.inputChat')"
                    />
              </van-form>
            <my-icon class='iconName_style' :iconName="iconName" fontSize="34" @click='showOrHide'></my-icon>
          </div>
          <div>
            <!-- 表情部分 -->
            <div class="emojis-box" v-if="isShowEmoj">
              <div class="emojis">
                <div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
                  <img :src="require(`@/assets/emotion/${emojiMap[item]}`)" />
                </div>
              </div>
            </div>
            <van-button v-if="isShowEmoj" @click="sendTextMessage" type="primary" class='send_style'>发送</van-button>
          </div>
         </div>

        <div class="send-header-bar" v-show="isFocus"></div>
      </div>
    </van-popup>
</template>
<script>
  import imMixins from '@/mixins/im.mixins.js'
  export default {
    mixins: [imMixins],
    components: {
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    directives: {
      scroll: {
        inserted(el) {
          el.scrollIntoView()
        }
      }
    },
    data() {
      return {
        imList: [],
        isFocus: false,
        autofacus: false,
        roomId: this.$route.query.roomID,
        isShowEmoj: false,
        iconName: 'icon-biaoqing_32_hui',

        startInputPos: 0, //记录输入框光标位置
        endInputPos: 0
      }
    },

    created() {
      this.getHistoryMessage()
      this.initListener()
      this.initEnterHandle()
    },

    computed: {
      changeSize() {
        console.log('[debug info] isIOS && foucus', this.isIOS , this.isFocus)
        return this.isIOS && this.isFocus
      },

      isIOS() {
        return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
      }
    },

    mounted() {
      window.addEventListener('touchmove', this.stopScroll, true)
    },

    methods: {

      stopScroll(e) {
        let target = e.target
        if(target && target.tagName === 'TEXTAREA'){ 
          e.stopPropagation()
        }
      },

      keyupValid(e) {
        if (!this.chatContent) {
           e.target.blur()
           return false
        }
      },

      validChat() {
        //安卓兼容性问题 输入时保持滚动条在当前位置
        this.$refs.chatInput.scrollTop = this.$refs.chatInput.scrollHeight

        if (this.chatContent.length > 300) {
          this.$toast(this.$t('meeting.wordLimt'))
          this.chatContent = this.chatContent.substring(0, 300)
          return
        }
      },

      clickBlur(e) {
        this.startInputPos = e.target.selectionStart || 0; // input 第0个字符到选中的字符
        this.endInputPos = e.target.selectionEnd || 0; // 选中的字符到最后的字符

        this.isFocus = false;
      },
      clickFocus(e) {

        this.isFocus = true;
        console.log('[debug info] iconName', this.iconName);
        console.log(e, 'event')
        this.iconName = 'icon-biaoqing_32_hui'
        this.isShowEmoj = false;
        this.autofacus = true;
        // setTimeout(() => {
        //   this.isFocus = true;
        //   console.log('[debug info] showOrHide() | 唤起键盘并保证键盘在最底部：', 0, document.body.offsetHeight)
        //   window.scrollTo(0, document.body.offsetHeight);
        // }, 0)
      },
      chooseEmoji(item) {
        const result = this.chatContent.substring(0, this.startInputPos)
        + item
        + this.chatContent.substring(this.endInputPos)
        if (result.length > 300) {
          this.$toast(this.$t('meeting.wordLimt'))
          return
        }

        this.startInputPos += item.length
        this.endInputPos += item.length
        this.chatContent = result
      },

      emojClose() {
        this.iconName = 'icon-biaoqing_32_hui';
        this.isShowEmoj = false
        return
      },

      chatClose() {
        this.iconName = 'icon-biaoqing_32_hui';
        this.isShowEmoj = false
        this.$emit('close')
      },
      getContainer() {
        return document.querySelector('.meeting-page')
      },
      showOrHide() {
        if (this.iconName == 'icon-biaoqing_32_hui') {
          this.iconName = 'icon-jianpan_32_hui'
          this.isShowEmoj = true;
          this.autofacus = false;
          // 设置 readOnly 并， 设置 course 的位置。

        } else {
          this.autofacus = true;
          this.isFocus = true;

          setTimeout(() => {
            this.$refs.chatInput.focus();
            // this.$refs.chatInput.focus();
            // 575
            console.log('[debug info] showOrHide() | 唤起键盘并保证键盘在最底部：', 0, document.body.offsetHeight)
            window.scrollTo(0, document.body.offsetHeight);
          }, 0)

        }
        console.log(this.autofacus, 'this.autofacusthis.autofacus')

      },
    },

    beforeDestroy() {
      window.removeEventListener('touchmove', this.stopScroll)
    },

  }

</script>
<style lang="less" scoped>
 .chat-pop {
    height: 1080px;
    position: fixed !important;

    &.iosfocus {
      height: 600px !important;
    }
  }
 textarea {
   -webkit-touch-callout:none; /*系统默认菜单被禁用*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
 }
 .popup_content {
      position: relative;
      width: 100%;
      height:100%;
      color: #000;
      display: flex;
      flex-direction: column;
      .title {
        height: 88px;
        padding: 20px 40px;
        font-size: 32px;
        text-align: center;
        .icon_style {
          float: left;
        }
      }

      #message-send-pop-wrapper{
        flex: 1;
        overflow: scroll;
      }

      .message-list {
        width: 100%;
        padding: 40px;
        .message-wrapper {
          margin-bottom: 20px;

          .msg-time {
            margin: 0 auto 20px;
            font-size: 12px;
            color: #656565;
            text-align: center;
          }


          .public-message(@direction) {
            color: #FFFFFF;
            display: flex;
            flex-direction: @direction;

            .message-img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            .tip-text {
              padding: 4px 35px
            }

            .tip-leave {
              padding: 4px 40px
            }

            .message-text {
              font-size: 30px;
              line-height: 42px;
              color: #333333;
              //white-space: normal;
              white-space: pre-wrap;
              word-break: break-all;
              word-wrap: break-word;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            }
          }

          .public-message-container(@float, @background) {
            display: inline-block;
            position: relative;
            border-radius: 12px;
            padding: 22px 18px;
            text-align: left;
            float: @float;
            background: @background;

            >img {
              vertical-align: middle;
            }
          }

          .public-message-triangle() {
            width: 0;
            height: 0;
            border-bottom: 18px solid transparent;
            position: absolute;
            top: 10px;
          }

          .public-message-nick(@direction, @margin, @color, @colors, @size, @sizes, @right) {
            font-size: 14px;
            position: relative;
            line-height: 18px;
            word-wrap: break-word;
            white-space: normal;
            /* width: 78%; */
            max-width: 78%;
            margin-left: 6px;

            .message-nick-box {
              display: flex;
              flex-direction: @direction;
              /* margin-bottom: @margin; */

              .message-nick {
                font-size: 14px;
                color: @color;
                margin-right: 8px;
              }

              .message-date {
                font-size: @size;
                color: @colors;
              }
            }
          }

          .public-tip() {
            width: 100%;
            text-align: center;
            position: relative;
            font-size: 12px;
            text-align: center;
            line-height: 16px;
            word-wrap: break-word;
            white-space: normal;

            .tips-img {
              display: inline-block;
              width: 20px;
              vertical-align: center;
            }
          }

          .message-box-other {
            .public-message(none);

            .message-item {
              .public-message-nick(none, 6px, #333, #999, 14px, 12px, left);

              .message-container {
                .public-message-container(left, #f5f5f5);

                .triangle {
                  .public-message-triangle();
                  left: -18px;
                  border-right: 18px solid #f5f5f5;
                }
              }
            }

            .tip-text,
            .tip-leave {
              color: #00AF3D; //#258ff3//#fea602
              .public-tip();
            }

          }

          .message-box {
            .public-message(row-reverse);

            .message-item {
              .public-message-nick(row-reverse, 10px, #999, #333, 14px, 14px, right);

              .message-container {
                .public-message-container(right, rgba(29, 175, 105, 0.2));

                .triangle {
                  .public-message-triangle();
                  border-left: 18px solid rgba(29, 175, 105, 0.2);
                  right: -18px;
                }
              }
            }

            .tip-text,
            .tip-leave {
              .public-tip();
            }
          }
        }

        /** androw 兼容性问题 最后一条消息间距 ***/
        .and-focus-wrapper:last-child {
          margin-bottom: 130px;
        }

      }

      .send-header-bar {
        width: 100%;
        overflow:hidden;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        background: #FAFAFA;


        .send-header-button {
          width: 100%;
          display: flex;
          /* position: fixed; */
          justify-content: space-between;
          padding: 24px 30px;
          .input-box {
            width: 596px;
            min-height: 64px;
            max-height: 150px;
            // overflow-y: scroll;
            border: 1px solid rgba(0, 0, 0, 0.3);
            background: #FFFFFF;
            border-radius: 6px;
            -webkit-overflow-scrolling: touch;
          }
          .chat-input {
            // width: 96%;
            // margin:0 auto;
            background: #FFFFFF;
            border-radius: 6px;
            outline: none;
            resize: none;
	          word-break: break-all;
            padding: 8px;
            padding-left: 10px;
            // overflow-y:hidden;
            overflow-y: auto;
          }
          .chat-valid-error {
            border:1px solid #FF4D4F;
          }
          .iconName_style {
            margin-top: 10px;
            margin-right: 5px;
          }
        }

        .emojis-box {
          width: 100%;
          background: #FFFFFF;

          .emojis {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .emoji {
              img {
                height: 80px;
                width: 80px;
                padding: 18px;
                margin: 24px 16px;
              }

              &:hover {
                background: rgba(0, 0, 0, 0.05);
                border-radius: 4px;
              }
            }

          }

        }

        .send_style {
          width: 136px;
          height: 64px;
          background: #1AB370;
          border-radius: 8px;
          margin-left: 76%;
          margin-top:20px;
          margin-bottom:22px;
        }
      }

  }

  /deep/ .imgMaxHeight .van-image__img{
    max-width: 300px; max-height: 300px;
    display: block;
    overflow: hidden;
  }
</style>
