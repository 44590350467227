<template>
  <div class="content">
    <setting v-model="showSetting"></setting>

    <popup-bottom :value="value" @input="(val) => this.$emit('input', val)">
      <div class="content">
        <div class="text chat_dis" @click="clickWenjuan">
          <div>问卷</div>
          <div class='circle' v-if='$parent.$parent.$parent.isShowWenjuanTips'></div>
        </div>
        <div class="text chat_dis" @click="screenHot"  v-if='isHost || isCoHost'>
          <div>云截图</div>
        </div>
        <div class="text chat_dis" @click="clickChat">
          <div>{{ $t("meeting.chat") }}</div>
          <div class='circle' v-if='showNewChatTips'></div>
        </div>
        <!-- <div @click="showShare = true" class="text">
          {{ $t("meeting.invitation") }}
        </div> -->
        <div v-if="!isOrdinaryUser" @click="showSetting = true" class="text">
          {{ $t("meeting.setMeeting") }}
        </div>
        <div @click="logReportToServer" class="text borderNone">{{ $t("meeting.log") }}</div>
      </div>
    </popup-bottom>


    <!-- <Share v-model="showShare" /> -->
  </div>
</template>
<script>
import PopUpBottom from "@/components/PopUpBottom/index";
// import Share from "@/components/Share/index";
import Setting from "./Setting.vue";
import dayjs from "dayjs";
import Logan from "logan-web";
import { getUuid } from "@/utils/auth";

import { reportLogUrl } from '@/config/index';
import { ROLE_CODE } from "@/constant/index";
import { fetchMeetControl } from '@/api/meet'
import { CONTROL_COMMAND } from "@/constant/index";
import { loganLog } from "@/utils/log"


export default {
  components: {
    "popup-bottom": PopUpBottom,
    setting: Setting,
    // Share
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showSetting: false,
      showShare: false,
    };
  },
  computed: {
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },
    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    },
    showNewChatTips () {
      //是否展示最新消息提醒
      return this.$store.state.meet.showNewChatTips
    },
    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    },
  },
  methods: {

    clickWenjuan() {
      this.$parent.$parent.$parent.isOpenWenjuan = true
      this.$parent.$parent.$parent.isShowWenjuanTips = false
      this.$parent.$parent.$parent.isOpenWenjuanAsk = false
      this.$parent.$parent.$parent.questionnaireUrl = `${this.$parent.$parent.$parent.questionnaireUrlStr}&roleCode=${this.selfInfo.roleCode}`
    },

    async screenHot() {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.CLOUD_SCREENHOT,
        }, this.$configs);

        this.$toast(this.$t("云截图成功"));
      } catch (error) {
        this.$toast(this.$t("云截图错误"));
        loganLog(`云截图错误：${JSON.stringify(error)}`,'[more]')
      }
    },
    
    clickChat() {
      this.$parent.$parent.showChat = true
      this.$store.commit("meet/updateGlobalMeetState", {
        showNewChatTips: false //关闭新消息提醒
      })
    },
    async logReportToServer() {
      this.$toast(this.$t("meeting.loging"));
      const { roomId, peerId, userId } = this.$configs;
      const currentTimestamp = new Date().getTime();
      const endDate = dayjs(currentTimestamp).format("YYYY-MM-DD");
      const startDate = dayjs(currentTimestamp)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
         console.log(this.$configs,999)

      try {
        let res = await Logan.report({
          reportUrl: reportLogUrl,
          deviceId: "wechat_" + getUuid(),
          fromDayString: startDate,
          toDayString: endDate,
          customInfo: JSON.stringify({
            peerId: peerId,
            roomId: roomId,
            userId: userId,
            userName: localStorage.getItem('displayNameKey'),
            conferenceNo: localStorage.getItem('localConferenceNoKey')
          }),
          webSource: "wechat",
        });
        for (const key in res) {
          if (Object.hasOwnProperty.call(res, key)) {
            const element = res[key];
            if (element.msg !== "Report succ") {
              if (element.msg === "No log exists") {
                this.$dialog.alert({
                  confirmButtonColor: "#000",
                  message: this.$t("meeting.noLog"),
                });
              }
            } else {
              this.$dialog.alert({
                message: this.$t("meeting.logSuccess"),
              });
            }
          }
        }
        console.log("上传日志", res);
      } catch (error) {
        console.error("上传日志失败", error);
        this.$dialog.alert({
          message: this.$t("meeting.logFail"),
        });
      }
    },
  },
};
</script>

<style scoped  lang="less">

.content {
  background: #fff;
  width: 100%;
  border-radius: 12px 12px 16px 16px;
  .text {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    color: #333;
    border-bottom: 1px solid #eeeeee;
  }
  .chat_dis{
    position: relative;
    .circle{
      position: absolute;
      width: 16px;
      height: 16px;
      background: #FF4D4F;
      padding: 0;
      border-radius: 50%;
      top: 36px;
      left: 56%;
  }
  }

  .borderNone {
    border: none;
  }
}

.tit {
  width: 192px;
  height: 88px;
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  line-height: 88px;
  margin-left: 40px;
}
.share {
  color: #333;
  margin-left: 40px;
  .imgBox {
    width: 110px;
    height: 110px;
    background: #ffffff;
    border-radius: 24px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .copyLink {
    width: 60px;
    height: 60px;
  }
  .info {
    color: #000;
    font-size: 22px;
    margin-left: 8px;
    margin-top: 14px;
  }
  .class_name {
    text-align: center;
    border-radius: 12px;
    color: #999999;
    .flex_style {
      /* display: flex; */
      text-align: center;
      .loading_style {
        width: 50px;
        height: 50px;
      }
    }
    .dialog-content {
      font-size: 18px;
      margin: 50px 0;
    }
  }
}
</style>
