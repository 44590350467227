<template>
  <div>
    <!-- 会控成员操作弹窗 -->
    <pop-up-bottom 
        :value="value" 
        @input="val => this.$emit('input', val)">
      <div class="menu-content">
        <div class="text title">{{ member.userName }}</div>
        <!-- 解除静音 -->
        <div class="text" @click="setUnMuteHandle(member.userId,member)" v-if="isShowUnMute(member)">{{$t('meeting.audioUnMute')}}</div>
        <!-- 静音 -->
        <div class="text" @click="setMuteHandle(member.userId, member)" v-if="isShowMute(member)">{{$t('meeting.audioMute')}}</div>
        <!-- 改名 -->
        <div class="text" @click="changeNameClickHandle(member)" v-if="isShowChangeName(member)">{{$t('meeting.changeName')}}</div>
        <!-- 设为主持人 -->
        <div class="text" @click="setHostClickHandle(member)" v-if="isShowMore && isShowSetHost(member)">{{$t('meeting.setMain')}}</div>
        <!-- 设为联席主持人 -->
        <div class="text" @click="setCoHostHandle(member)" v-if="isShowMore && isShowSetCoHost(member)">{{$t('meeting.goHost')}}</div>
        <!-- 撤销联席主持人 -->
        <div class="text" @click="setUserHandle(member)" v-if="isShowMore && isShowNotSetCoHost(member)">{{$t('meeting.removeHost')}}</div>
        <!-- 停止该成员的视频 -->
        <div class="text" @click="stopVideHandle(member)" v-if="isShowMore && isShowStopVideo(member)">{{$t('meeting.stopVideo')}}</div>
        <!-- 停止该成员的共享 -->
        <div class="text" @click="stopShareClickHandle(member)"  v-if="isShowMore && isShowStopShare(member)">{{$t('meeting.stopShare')}}</div>
        <!-- 手放下 -->
        <div class="text" @click="singDownHandHandle(member)" v-if="isShowMore && isShowStopHand(member)">{{$t('meeting.putHands')}}</div>
        <!-- 移除会议 -->
        <div class="text red" @click="removeMemberClickHandle(member)" v-if="isShowMore && isShowStopMeet(member)">{{$t('meeting.removeMeet')}}</div>
      </div>
    </pop-up-bottom>
    <!-- 改名弹窗 -->
    <ModifyNickname :originName='currentSelectItem.userName' :dialogVisible='dialogVisible' @close='dialogVisible = false' @submitNickName='submitNickName'/>
  </div>
</template>
<script>
  import { ROLE_CODE, CONTROL_COMMAND } from "@/constant/index";
  import PopUpBottom from '@/components/PopUpBottom/index';
  import ModifyNickname from '@/components/dialog/ModifyNickname.vue';
  import { fetchMeetControl } from '@/api/meet';
  import { strMiddleEllipsis } from "@/utils/str"
  import { loganLog } from "@/utils/log"
  export default {
    components: {
      PopUpBottom,
      ModifyNickname
    },

    props: {
      value: {
        type: Boolean,
        default: false
      },
      member: {
        type: Object,
        default: () => {}
      },
      isShowMore: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        dialogVisible: false,
        currentSelectItem: {}
      }
    },

    computed: { 
       allowSelfUnmute() {
         return this.$store.state.meet.allowSelfUnmute;
       },

       selfInfo() {
         return this.$store.getters["member/getUser"](this.$configs.peerId);
       },

       ownerId() { // 会议所有者id
         return this.$store.state.meet.ownerId
       },

       isHost() {
        return this.selfInfo.roleCode === ROLE_CODE.HOST;
       },

       isCoHost() {
         return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
       },

       isOrdinaryUser() {
         return this.selfInfo.roleCode === ROLE_CODE.USER;
       },
    },
   
    methods: {
      // 是否为当前用户
      isCurrentUser(item) {
        return item.userId === this.$configs.peerId;
      },

     /************************** 会控按钮显示隐藏控制 ***************************/
     // 是否显示解除静音
     isShowUnMute(item) {
      if (item.isUseHuaTong) { // 开启音频状态
        return false
      } else { // 静音状态
        if (this.isOrdinaryUser) { // 普通用户
          return this.isCurrentUser(item) // 移动到自己，显示静音按钮
        } else if (this.isCoHost) { // 联席主持人
          return item.roleCode !== ROLE_CODE.HOST
        } else { // 主持人
          return true
        }
      }
    },
    //是否显示静音按钮
    isShowMute(item) { 
      if (item.isUseHuaTong) { // 开启音频状态
        if (this.isOrdinaryUser) { // 普通用户
          return this.isCurrentUser(item) // 移动到自己，显示静音按钮
        } else if (this.isCoHost) { // 联席主持人
          return item.roleCode !== ROLE_CODE.HOST
        } else { // 主持人
          return true
        }
      } else { // 静音状态
        return false
      }
    },
    //是否显示改名
    isShowChangeName(item) { 
      if(this.isCurrentUser(item)) {
        return true
      }

      if(this.isShowMore) {
        // 是否显示下拉菜单中的改名
        if (this.isCoHost) {
          return item.roleCode !== ROLE_CODE.CO_HOST;
        } else {
          return true;
        }
      }

      return false
    },


    //是否显示设置联席主持人按钮
    isShowSetCoHost(item) { 
      // return item.roleCode !== ROLE_CODE.CO_HOST

      if (this.isHost) {
        return item.roleCode === ROLE_CODE.USER;
      } else {
        return false;
      }
    },
     //是否显示撤销联席主持人
    isShowNotSetCoHost(item) {
      if (this.isHost) {
        return item.roleCode === ROLE_CODE.CO_HOST;
      } else {
        return false;
      }
    },
    //是否显示设置为主持人按钮
    isShowSetHost() {
      return this.isHost;
    },
    // 是否显示停止视频
    isShowStopVideo(item) {
      return item.isUseShiPin;
    },
    // 是否显示停止分享
    isShowStopShare(item) { 
      return item.isShare || item.isShareDoc;
    },
    // 是否显示放下手
    isShowStopHand(item) {
      if (this.isHost || this.isCoHost) {
        // 主持人
        return item.roleCode === ROLE_CODE.USER && item.isRaiseHand;
      } else {
        return false;
      }
    },
    // 是否显示移除会议
    isShowStopMeet(item) { 
      if (this.isHost || this.isCoHost) { // 主持人或者是联席主持人
        return (item.roleCode === ROLE_CODE.USER)
      } else {
        return false;
      }
    },

    /************************** 会控操作 ***************************/
     /**
     * 静音
     */
    async setMuteHandle(userId, member) {
      // 静音本地音频设备
      if (userId === this.selfInfo.userId) {
        try {
          await sdk.rtc.muteLocalAudio(true).then(()=>{
            // 更改自身状态
            this.$store.commit('member/updateUser', {
              userId,
              isUseHuaTong: false
            })
          })
        } catch (error) {
          loganLog({
            msg: '成员列表静音操作',
            userId: this.$configs.peerId,
            userName: this.selfInfo.userName,
            error
          }, '会控异常|自己静音')
        }
      } else { 
        console.log('操作他人静音')

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.SINGLE_MUTE,
            toPeerId: userId
          }, this.$configs)

        //this.$toast('已将该成员静音')  
        } catch (error) {
          console.log(error)
          loganLog(error, '会控异常|他人静音')
        }
      }
    },

     /**
     * 解除静音
     */
     async setUnMuteHandle(userId) {
      if (userId === this.selfInfo.userId) { // 操作自己解除静音

        this.$eventBus.$emit('unmute');
      } else { 
        console.log('操作他人解除静音')

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.SINGLE_UNMUTE,
            toPeerId: userId
          }, this.$configs)
        } catch (error) {
          console.log(error)
          loganLog(error, '会控异常|他人解除静音')
        }

      }
    },
    
    // 唤起改名弹窗
    changeNameClickHandle(item) {
      this.currentSelectItem = item
      this.dialogVisible = true
    },

    async submitNickName(nickname){
      if (!nickname) {
         return
      }

      if (this.isCurrentUser(this.currentSelectItem)) {
        console.log("操作自己");

        try {
          // im的改名信令
          sdk.im.updateNickAndAvatar({
            nickname: nickname
          })
        } catch (error) {
          loganLog(error, '会控异常|im异常|自己改名')
        }

        try {
          // rtc信令改名
          sdk.rtc.changeLocalDisplayName(nickname)
        } catch (error) {
          loganLog(error, '会控异常|rtc异常|自己改名')
        }

        this.dialogVisible = false
      } else {
        // 给其他人改名
        console.log("操作他人");

        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.CHANGE_NAME,
            metaData: {
              userName: nickname
            },
            toPeerId: this.currentSelectItem.userId,
          }, this.$configs);

          this.dialogVisible = false

          // TODO: 调用成功后的提示
        } catch (error) {
          console.log(error);
          loganLog(error, '会控异常|他人改名')
        }
      }
    },
    
    /**
     * 设置主持人点击事件
     */
    setHostClickHandle(item) {
      this.currentSelectItem = item

      const commonText = this.$t('meeting.confirmWill') + strMiddleEllipsis(item.userName, 10, 3, 2) + this.$t('meeting.setMain')
      this.$dialog.confirm({
        title: this.$t('meeting.setMain'),
        message: commonText,
        confirmButtonText: this.$t('login.sure'),
        cancelButtonText: this.$t('login.cancel')
      })
      .then(() => {
        try {
          fetchMeetControl({
            command: CONTROL_COMMAND.TRANSFER_HOST,
            toPeerId: this.currentSelectItem.userId,
          }, this.$configs)

        } catch (error) {
          console.log(error);
          loganLog(error, '会控异常|设置主持人')
        }
      })
    },

    /**
     * 设为联席主持人，变为普通用户
     */
    async setCoHostHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.GRANT,
          metaData: {
            roleCode: ROLE_CODE.CO_HOST,
          },
          toPeerId: item.userId,
        }, this.$configs);

        this.$toast(`${this.getEllipsisStr(item.userName)}`+ this.$t('meeting.beHost'))
      } catch (error) {
        this.$toast.fail(this.$t('meeting.goFail'))
        loganLog(error, '会控异常|设置联席主持人')
      }
    },

     /**
     * 撤销联席主持人
     */
    async setUserHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.GRANT,
          metaData: {
            roleCode: ROLE_CODE.USER,
          },
          toPeerId: item.userId,
        }, this.$configs);

        this.$toast(this.$t('meeting.Withdrawn') + `${this.getEllipsisStr(item.userName)}`+this.$t('meeting.moderator'))
      } catch (error) {
        this.$toast.fail(this.$t('meeting.hostFail'))
        loganLog(error, '会控异常|撤销联席主持人')
      }
    },

    getEllipsisStr(str) {
      return strMiddleEllipsis(str, 10, 3, 2)
    },

     /**
     * 停止视频
     */
    async stopVideHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.SHIELD_VIDEOS,
          toPeerId: item.userId
        }, this.$configs)

        // TODO: 调用成功后的提示
        //this.$toast(`用户 ${item.userName} 视频采集已关闭`)
      } catch (error) {
        console.log(error)
        loganLog(error, '会控异常|停止视频')
      }
    },

    /**
     * 停止成员分享点击事件
     */
    async stopShareClickHandle(item) {
      this.$dialog.confirm({
        title: this.$t('meeting.endShare'),
        confirmButtonText: this.$t('login.sure'),
        cancelButtonText: this.$t('login.cancel'),
        message: this.$t('meeting.surShare')
      })
      .then(async () => {
         try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.STOP_SHARE,
            toPeerId: item.userId
          }, this.$configs)
        } catch (error) {
          console.error(`停止指定用户屏幕共享失败--->${error}`)
          loganLog(error, '会控异常|停止共享')
        }
      })
    },

    /**
     * 主持人将某个成员手放下
     */
    async singDownHandHandle(item) {
      try {
        await fetchMeetControl({
          command: CONTROL_COMMAND.SINGLE_HAND_DOWN,
          toPeerId: item.userId,
        }, this.$configs);

        // TODO: 调用成功后的提示
        //this.$toast(`用户 ${item.userName} 举手已处理`)
      } catch (error) {
        console.log(error);
        loganLog(error, '会控异常|他人手放下')
      }
    },

    /**
     * 移除会议点击事件
     */
    removeMemberClickHandle(item) {
      this.$dialog.confirm({
        title: this.$t('meeting.removeMeet'),
        confirmButtonText: this.$t('login.sure'),
        cancelButtonText: this.$t('seeting.thinkAgain'),
        message: `${this.$t('meeting.confirmWill')} ${item.userName} ${this.$t('meeting.moveMeet')}`
      })
      .then(async () => {
         try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.REMOVE_USER,
            toPeerId: item.userId
          }, this.$configs)

          this.$toast(this.$t('meeting.removeSuccess'))
        } catch (error) {
          this.$toast.fail(this.$t('meeting.removeMeetFail'))
          loganLog(error, '会控异常|移出会议')
        }
      })
    },

    

    }
  }

</script>
<style lang="less" scoped>
 /********** 会控菜单弹窗 ************/
 .text_overflow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-content {
  background: #fff;
  width: 100%;
  border-radius: 12px 12px 16px 16px;
  .text {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    color: #333;
    border-bottom: 1px solid #eeeeee;
    position: relative;
  }
  .text.title {
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 400;
    color: #999999;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    .text_overflow();
  }
  .text.red {
    color:#F35B67
  }
  .text .icon {
    position: absolute;
    top: 24px;
    right: 159px;
  }
}
.menu-content .text:last-child {
  border-bottom: none;
}
</style>
