<template>
   <!--- 修改昵称弹窗 --->
    <van-dialog 
      v-model="dialogShow"
      :confirmButtonText="$t('meeting.ok')"
      confirmButtonColor="#1DAF69"
      :before-close="beforeCloseHandle"
      class="win-pwd"
      show-cancel-button>
      <div class="title">{{ $t('meeting.changeName') }}</div>
      <van-field 
          :placeholder="$t('seeting.inputUserName')" 
          class="pwd-input" 
          :class="{'active': nicknameValue }" 
          v-model="nicknameValue" />
    </van-dialog>
</template>

<script>
// import { checkSmallPwd  } from '@/utils/check'
import { loganLog } from "@/utils/log"
import { wordsFilter} from '@/api/meet'

export default {
  data() {
    return {
      dialogShow:false,
      nicknameValue:'',
    }
  },
  props: ['originName','dialogVisible'],
  watch: {
    dialogVisible: {
      handler(value) {
        this.dialogShow = value;
      },
      immediate: true
    },
    originName: {
      handler(value) {
        if (value) this.nicknameValue = value;
      },
      immediate: true
    },
  },
  mounted() {
  },
  
  methods: {
    async beforeCloseHandle(action, done) {
      if (action === 'confirm') {
        //校验
        try {
          await wordsFilter({ 
            bizType:'meeting',
            text:this.nicknameValue
          })
        } catch (error) {
          let _msg = [102,103,199001].includes(error.code) ? '消息内容存在敏感词，请检查后重试' : '消息内容' + error.msg
          this.$toast(_msg)
          loganLog(`发送消息错误--error:${JSON.stringify(error)}，test:${this.nicknameValue}`)
          return done(false)
        }

        if(!this.nicknameValue) {
          this.$toast(this.$t('reg.nameEmpty'))
          return done(false)
        } else {
          done()
          this.$emit('close')
          this.$emit('submitNickName', this.nicknameValue)
        }
      } else {
        done()
        this.$emit('close')
      }
    },

  }
};
</script>

<style lang="less" scoped>
.win-pwd {
     width: 580px;
     .title {
        font-size: 36px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        margin-top: 38px;
     }
      
     .pwd-input {
        margin: 0 auto;
        margin-top: 32px;
        width: 500px;
        height: 72px;
        line-height: 72px;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 40px;
     }

     .pwd-input.active {
       border: 1px solid #1AB370;
     }

   } 

/deep/ .van-dialog {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

/deep/ .pwd-input .van-field__control {
  color: #1AB370;
}
</style>
