<template>
   <!--- 成为主持人弹窗 --->
    <van-dialog 
      v-model="dialogShow"
      :confirmButtonText="$t('login.sure')"
      :cancelButtonText="$t('login.cancel')"
      confirmButtonColor="#1DAF69"
      :before-close="beforeCloseHandle"
      class="win-pwd"
      show-cancel-button>
      <div class="title">{{$t('meeting.becomeHost')}}</div>
      <van-field 
          :placeholder="$t('login.onlyNo')" 
          class="pwd-input" 
          type="digit"
          :class="{'active': hostPassword }" 
          v-model="hostPassword" />
    </van-dialog>
</template>

<script>
import { checkSmallPwd  } from '@/utils/check'
import { loganLog } from "@/utils/log"
import { CONTROL_COMMAND, THROTLE_TIME } from "@/constant/index";
import { fetchMeetControl } from '@/api/meet'
import { debounce } from 'lodash'
export default {
  data() {
    return {
      dialogShow:false,
      hostPassword:'',
    }
  },
  props: ['dialogVisible'],
  watch: {
    dialogVisible: {
      handler(value) {
        if (value) {
          this.hostPassword = ''
        }
        this.dialogShow = value;
      },
      immediate: true
    },
  },
  mounted() {
  },
  
  methods: {
    beforeCloseHandle: async function(action, done) {
      if (action === 'confirm') {
        //校验
        if(!this.hostPassword) {
          this.$toast(this.$t('reg.emptyPwd'))
          return done(false)
        } else if(!checkSmallPwd(this.hostPassword)) {
          this.$toast(this.$t('reg.huiyiPwd'))
          return done(false)
        } else {
          await this.becomeHostSubmitHandle()
          .then(res => {
            done()
            this.$emit('close')
          })
          .catch(err => {
            return done(false)
          }) 
        }
      } else {
        done()
        this.$emit('close')
      }
    },

    /**
     * 成为主持人提交
     */
    becomeHostSubmitHandle: debounce(async function () {
       try {
        const ownerPassword = this.hostPassword
        await fetchMeetControl({
          command: CONTROL_COMMAND.APPLY_HOST,
          metaData: {
            ownerPassword
          }
        }, this.$configs)

      } catch (error) {
        this.$toast(error.msg)
        loganLog(error, '会控异常|成为主持人')
        throw error
      }
    }, THROTLE_TIME, { leading: true, trailing: false })

  }
};
</script>

<style lang="less" scoped>
.win-pwd {
     width: 580px;
     .title {
        font-size: 36px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        margin-top: 38px;
     }
      
     .pwd-input {
        margin: 0 auto;
        margin-top: 32px;
        width: 500px;
        height: 72px;
        line-height: 72px;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 40px;
     }

     .pwd-input.active {
       border: 1px solid #1AB370;
     }

   } 

/deep/ .van-dialog {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

/deep/ .pwd-input .van-field__control {
  color: #1AB370;
}
</style>
